<template>
  <div class="devices">
    <div class="sub-wrap">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left">
            <img src="../../assets/icons/adtwo.svg" />
            <h2>{{ $t("devices.yours") }}</h2>
            <h3>{{ $t("devices.yoursInfo") }}</h3>
          </div>
          <div class="header-right">
            <p>
              <router-link to="/devices/new" class="button button-ci button-round button-small">
                <img src="../../assets/icons/plus.svg" />
                <span>{{ $t("devices.addDisplay") }}</span>
              </router-link>
            </p>
          </div>
        </div>

        <devices-nav></devices-nav>

        <div v-if="loading">
          <div class="loading-wrap">
            <div class="loading-spinner"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="devices.length == 0">
            <div class="well">
              <h3>{{ $t("devices.noDisplays") }}</h3>
              <p>
                <router-link to="/devices/new" class="button button-ci button-round button-small">
                  <img src="../../assets/icons/plus.svg" />
                  <span>{{ $t("devices.addDisplayButton") }}</span>
                </router-link>
              </p>
            </div>
          </div>

          <div v-else>
            <div class="row row-gutter-20">
              <div v-for="device in devices" v-bind:key="device.uuid" class="col-6">
                <div class="device-wrap">
                  <router-link :to="'/devices/' + device.uuid" class="device-img">
                    <div v-if="device.device_image">
                      <img :src="device.device_image" width="285" height="160" />
                    </div>
                    <div v-else>
                      <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                    </div>
                  </router-link>
                  <div class="device-title">
                    <h3 style="margin-bottom: 0">
                      <router-link :to="'/devices/' + device.uuid">{{ device.name }}</router-link>
                    </h3>
                    <p style="margin-top: 10px; margin-bottom: 0">
                      {{ device.device_id }}
                    </p>
                    <a @click="delete_device(device.uuid)" class="device-delete material-icons">delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DevicesNav from "../../components/DevicesNav.vue";

export default {
  components: { DevicesNav },
  name: "devices",
  data() {
    return {
      errors: [],
      loading: true,
      devices: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/devices", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.devices = response.data.devices;
        });
    },
    delete_device(device) {
      if (confirm(this.$t("devices.deleteDisplay"))) {
        axios
          .delete(process.env.VUE_APP_BASE_API + "/devices/" + device, {
            headers: { Authorization: this.$store.getters.getToken },
          })
          .then((response) => {
            this.$notify({
              group: "notification",
              type: "success",
              text: this.$t("devices.deletedDiplay"),
            });
            console.log(response);
            this.get_data();
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
