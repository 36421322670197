<template>
  <div class="tab-nav cf">
    <ul class="nav nav-tabs cf">
      <li>
        <router-link to="/devices">{{ $t("global.devices") }}</router-link>
      </li>
      <li>
        <router-link to="/playlists">{{ $t("global.playlists") }}</router-link>
      </li>
      <li>
        <router-link to="/schedules">{{ $t("global.schedules") }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DevicesNav",
  data() {
    return {
      user: {},
    };
  },
  methods: {},
  mounted() {},
};
</script>
